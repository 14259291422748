import { switchCurrentPage } from "legacy/actions/pageActions";
import {
  showItemPropertyPane,
  updateActivePropertyInPropertyPane,
  updateHoveredPropertyInPropertyPane,
  updatePropertyPaneWidthAction,
} from "legacy/actions/propertyPaneActions";
import { selectWidgets } from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { PropertyPaneLayoutConfig } from "legacy/constants/WidgetConstants";
import { PropertyPaneItem } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { createImmerReducer } from "../createReducer";

const initialState: PropertyPaneReduxState = {
  isVisible: false,
  item: undefined,
  width:
    sessionStorage.getInt(SessionStorageKey.PROP_PANE_WIDTH) ??
    PropertyPaneLayoutConfig.defaultWidth,
  openPanels: [],
};

const propertyPaneReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(showItemPropertyPane, (state, action) => {
      const { item } = action.payload;

      if (item === undefined) {
        delete state.item;
      } else {
        state.item = item;
        state.isVisible = true;
      }
    })
    .addCase(
      ReduxActionTypes.HIDE_PROPERTY_PANE,
      (state: PropertyPaneReduxState) => {
        return {
          ...state,
          isVisible: false,
        };
      },
    )
    .addCase(
      updatePropertyPaneWidthAction,
      (state: PropertyPaneReduxState, action) => {
        return {
          ...state,
          width: action.payload.width,
        };
      },
    )
    .addCase(
      updateHoveredPropertyInPropertyPane,
      (state: PropertyPaneReduxState, action) => {
        return {
          ...state,
          hoveredPropertyPath: action.payload.hoveredPropertyPath,
        };
      },
    )
    .addCase(
      updateActivePropertyInPropertyPane,
      (state: PropertyPaneReduxState, action) => {
        return {
          ...state,
          activePropertyPath: action.payload.activePropertyPath,
        };
      },
    )
    .addCase(selectWidgets, (state, action) => {
      if (action.payload.widgetIds.length > 1) {
        return {
          ...state,
          item: undefined,
        };
      }
      return state;
    })
    .addCase(
      ReduxActionTypes.OPEN_PROPERTY_PANE_PANEL,
      (
        state: PropertyPaneReduxState,
        action: ReduxAction<
          { panelId: string },
          typeof ReduxActionTypes.OPEN_PROPERTY_PANE_PANEL
        >,
      ) => {
        return {
          ...state,
          openPanels: [...state.openPanels, action.payload.panelId],
        };
      },
    )
    .addCase(
      ReduxActionTypes.CLOSE_PROPERTY_PANE_PANEL,
      (
        state: PropertyPaneReduxState,
        action: ReduxAction<
          { index: number },
          typeof ReduxActionTypes.CLOSE_PROPERTY_PANE_PANEL
        >,
      ) => {
        if (action.payload.index >= state.openPanels.length) {
          return state;
        }
        return {
          ...state,
          openPanels: state.openPanels.slice(0, action.payload.index),
        };
      },
    )

    .addCase(
      ReduxActionTypes.CLOSE_PROPERTY_PANE_PANEL_BY_ID,
      (
        state: PropertyPaneReduxState,
        action: ReduxAction<
          { panelId: string },
          typeof ReduxActionTypes.CLOSE_PROPERTY_PANE_PANEL_BY_ID
        >,
      ) => {
        return {
          ...state,
          openPanels: state.openPanels.filter(
            (panelId) => panelId !== action.payload.panelId,
          ),
        };
      },
    )
    .addCase(switchCurrentPage, (state: PropertyPaneReduxState) => {
      return { ...state, openPanels: [] };
    }),
);

export interface PropertyPaneReduxState {
  isVisible: boolean;
  item?: PropertyPaneItem;
  hoveredPropertyPath?: string;
  activePropertyPath?: string;
  width?: number;
  openPanels: string[];
}

export default propertyPaneReducer;
