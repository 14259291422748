import { Dimension, Padding, WidgetTypes } from "@superblocksteam/shared";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { GeneratedTheme } from "legacy/themes";
import type {
  FlattenedWidgetLayoutProps,
  StaticWidgetProps,
  WidgetLayoutProps,
} from "legacy/widgets/shared";

export function hackyIsWidgetCollapsed(
  widget: FlattenedWidgetProps | WidgetLayoutProps | FlattenedWidgetLayoutProps,
) {
  return widget.height.value === 0;
}

export const ZERO_PADDING = {
  left: Dimension.px(0),
  right: Dimension.px(0),
  top: Dimension.px(0),
  bottom: Dimension.px(0),
};
/**
 * Use this as a fallback when the widget does not have a padding property assigned
 */
export const getWidgetDefaultPadding = (
  theme: GeneratedTheme,
  props?: {
    type?: WidgetType;
    containerStyle?: "card" | "none";
  },
): Padding | undefined => {
  if (props?.type === WidgetTypes.CANVAS_WIDGET) {
    if (props?.containerStyle === "none") return ZERO_PADDING;
    return theme.container.canvas.padding;
  }
  if (
    props?.type === WidgetTypes.SECTION_WIDGET ||
    props?.type === WidgetTypes.PAGE_WIDGET ||
    props?.type === WidgetTypes.MODAL_WIDGET
  ) {
    return ZERO_PADDING;
  }
  return undefined;
};

// return whether or not the border of the parent component should render detached
// while rendering the given child
export const borderIsOverlay = (
  widget: Pick<StaticWidgetProps, "type" | "children">,
  theme: GeneratedTheme,
  child?: Pick<StaticWidgetProps, "padding" | "type">,
) => {
  if (
    widget.type === WidgetTypes.CONTAINER_WIDGET ||
    widget.type === WidgetTypes.FORM_WIDGET ||
    widget.type === WidgetTypes.TABS_WIDGET
  ) {
    if (theme.version >= 3) {
      return true;
    }

    const renderedChild =
      child ?? (widget.children?.[0] as undefined | StaticWidgetProps);
    const yPadding = Padding.y(
      renderedChild?.padding ?? getWidgetDefaultPadding(theme, renderedChild),
    );
    return yPadding?.value === 0;
  }

  return false;
};

export const getBorderThickness = (
  widget: Omit<StaticWidgetProps, "parentColumnSpace" | "parentRowSpace">,
  theme: GeneratedTheme,
  child?: Pick<StaticWidgetProps, "padding" | "type"> | undefined, // only relevant for tabs atm
) => {
  let borderThickness = 0;
  switch (widget.type) {
    case WidgetTypes.TABS_WIDGET:
    case WidgetTypes.FORM_WIDGET:
    case WidgetTypes.CONTAINER_WIDGET: {
      if (!borderIsOverlay(widget, theme, child)) {
        borderThickness = 1;
      }
      break;
    }
    default:
      break;
  }
  return borderThickness;
};
