import { combineReducers } from "redux";

import evaluationDependencyReducer, {
  EvaluationDependencyState,
} from "./dependencyReducer";
import dynamicLayoutReducer, {
  DynamicLayoutState,
} from "./dynamicLayoutReducer";
import evaluationTypeReducer, { EvaluationType } from "./evaluationTypeReducer";
import lifecycleReducer, { LifecycleState } from "./lifecycleReducer";
import loadingEntitiesReducer, {
  LoadingEntitiesState,
} from "./loadingEntitiesReducer";
import nameIteratorReducer from "./nameIteratorReducer";
import evaluatedTreeReducer, { EvaluatedTreeState } from "./treeReducer";

export interface EvaluationsState {
  tree: EvaluatedTreeState;
  dependencies: EvaluationDependencyState;
  loadingEntities: LoadingEntitiesState;
  evaluationType: EvaluationType;
  lifecycle: LifecycleState;
  dynamicLayout: DynamicLayoutState;
  nameIterator: number;
}

export default combineReducers({
  tree: evaluatedTreeReducer,
  dependencies: evaluationDependencyReducer,
  loadingEntities: loadingEntitiesReducer,
  evaluationType: evaluationTypeReducer,
  lifecycle: lifecycleReducer,
  dynamicLayout: dynamicLayoutReducer,
  nameIterator: nameIteratorReducer,
});
