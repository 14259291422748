/* Utils *****************************************/
// Makes an interface T keep its props or be a string. Omits K
type WithOptionalStringProperties<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? T[P] : T[P] | string;
};

/* Base ******************************************/
export enum MenuItemType {
  Link = "LINK",
  Button = "BUTTON",
  Divider = "DIVIDER",
}

type BaseActionableMenuItem = {
  label: string;
  icon?: string; // maps to a Superblocks icon key
};

type LinkMenuItem = BaseActionableMenuItem & {
  type: MenuItemType.Link;
  href: string;
  targetBlank?: boolean; // opens in same tab/page if undefined
};

type ButtonMenuItem = BaseActionableMenuItem & {
  type: MenuItemType.Button;
  openOn?: "hover" | "click";
};

type Divider = {
  type: MenuItemType.Divider;
};

/* Component *************************************/
type WithChildren = { children?: ComponentMenuItem[] };
type ComponentLinkMenuItem = LinkMenuItem & WithChildren;
type ComponentButtonMenuItem = ButtonMenuItem & WithChildren;
type ComponentDivider = Divider;
export type ComponentMenuItem =
  | ComponentLinkMenuItem
  | ComponentButtonMenuItem
  | ComponentDivider;

/* DSL *******************************************/
type MappedMenuItemsContainer = {
  mapping: WithOptionalStringProperties<
    LinkMenuItem | ButtonMenuItem | DynamicTypeMapping,
    "type"
  >;
  data: string;
};

type DynamicTypeMapping = Omit<LinkMenuItem, "type"> &
  Omit<ButtonMenuItem, "type"> & {
    type: "DYNAMIC";
    dynamicType: string;
  };

export enum MenuChildrenType {
  Mapped = "mapped",
  Manual = "manual",
}

export type ActionableManualMenuItem = (LinkMenuItem | ButtonMenuItem) &
  ManualMenuChildren;
export type ManualMenuItem = ActionableManualMenuItem | Divider;

type ManualMenuChildren = {
  childrenType: MenuChildrenType;
  mappedChildren?: MappedMenuItemsContainer;
  manualChildren?: ManualMenuItem[];
};
