import { ApplicationScope } from "@superblocksteam/shared";
import type { WidgetType } from "legacy/constants/WidgetConstants";

/**
 * A kind is a category of objects whose properties can be displayed in the property pane.
 * For each kind, objects are further sub-categorized into types, e.g. for the widget kind we
 * have the following types: button, table, filepicker, etc.
 * The reason that we need this extra level of classification is because it is possible that
 * the (typescript) type of the properties object for an item can vary, i.e. widgets have properties
 * of (typescript) type WidgetProps, timers might use a completely different (typescript) type.
 * The rule is: if two items have the same (typescript) type for their properties object then
 * they have the same kind, otherwise they have different kinds.
 */
export enum ItemKinds {
  WIDGET = "WIDGET",
  NESTED_ITEM = "NESTED_ITEM",
  TIMER = "TIMER",
  STATE_VAR = "STATE_VAR",
  API_V1 = "API_V1",
  API_V2 = "API_V2",
  EMBED_PROP = "EMBED_PROP",
  ROUTE = "ROUTE",
  CUSTOM_EVENT = "CUSTOM_EVENT",
}

export interface PropertyPaneItem<ItemKind extends ItemKinds = ItemKinds> {
  kind: ItemKind;
  id: string;
  scope: ApplicationScope;
}

/**
 * The types of objects that have properties that can be displayed in the
 * properties pane.
 */
export type ItemWithPropertiesType = WidgetType | ItemTypeNonWidget;

/**
 * A flattened enum with all types of items from all kinds except widget.
 * To avoid conflicts, all types must be distinct across all kinds including the widget kind.
 */
export enum ItemTypeNonWidget {
  NESTED_ITEM = ItemKinds.NESTED_ITEM,
  TIMER = ItemKinds.TIMER,
  STATE_VAR = ItemKinds.STATE_VAR,
  API_V1 = ItemKinds.API_V1,
  API_V2 = ItemKinds.API_V2,
  EMBED_PROP = ItemKinds.EMBED_PROP,
  ROUTE = ItemKinds.ROUTE,
  CUSTOM_EVENT = ItemKinds.CUSTOM_EVENT,
}
