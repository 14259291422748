export function getUrlWithBranch(
  baseUrl: string,
  branch: string | undefined,
  suffixPath = "",
) {
  return (
    baseUrl +
    // TODO: get rid of the conditional and always use the path with the branch
    (branch ? `/branches/${encodeURIComponent(branch)}` : "") +
    suffixPath
  );
}
