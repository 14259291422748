import { generateReactKey } from "legacy/utils/generators";
import type { WidgetCardProps } from "legacy/constants/WidgetConstants";

export type WidgetCategory =
  | "COMMON"
  | "FORMS"
  | "CONTAINERS"
  | "MEDIA"
  | "ADVANCED"
  | "CUSTOM";

export type WidgetCardsCategorized = Array<{
  widgetCategory: WidgetCategory;
  label: string;
  widgetCards: WidgetCardProps[];
}>;

export const baseWidgets: WidgetCardsCategorized = [
  {
    widgetCategory: "COMMON",
    label: "",
    widgetCards: [
      {
        type: "TEXT_WIDGET",
        widgetCardName: "Text",
        key: generateReactKey(),
      },
      {
        type: "TABLE_WIDGET",
        widgetCardName: "Table",
        key: generateReactKey(),
      },
      {
        type: "CHART_WIDGET",
        widgetCardName: "Chart",
        key: generateReactKey(),
      },
      {
        type: "BUTTON_WIDGET",
        widgetCardName: "Button",
        key: generateReactKey(),
      },
      {
        type: "MENU_WIDGET",
        widgetCardName: "Menu",
        key: generateReactKey(),
      },
    ],
  },
  {
    widgetCategory: "FORMS",
    label: "Forms",
    widgetCards: [
      {
        type: "FORM_WIDGET",
        widgetCardName: "Form",
        key: generateReactKey(),
      },
      {
        type: "INPUT_WIDGET",
        widgetCardName: "Input",
        key: generateReactKey(),
      },
      {
        type: "DROP_DOWN_WIDGET",
        widgetCardName: "Dropdown",
        key: generateReactKey(),
      },
      {
        type: "DATE_PICKER_WIDGET",
        widgetCardName: "DatePicker",
        key: generateReactKey(),
      },
      {
        type: "CHECKBOX_WIDGET",
        widgetCardName: "Checkbox",
        key: generateReactKey(),
      },
      {
        type: "SWITCH_WIDGET",
        widgetCardName: "Switch",
        key: generateReactKey(),
      },
      {
        type: "RADIO_GROUP_WIDGET",
        widgetCardName: "Radio",
        key: generateReactKey(),
      },
      {
        type: "RICH_TEXT_EDITOR_WIDGET",
        widgetCardName: "Rich Text Editor",
        key: generateReactKey(),
      },
      {
        type: "FILE_PICKER_WIDGET",
        widgetCardName: "FilePicker",
        key: generateReactKey(),
      },
    ],
  },
  {
    widgetCategory: "CONTAINERS",
    label: "Containers",
    widgetCards: [
      {
        type: "MODAL_WIDGET",
        widgetCardName: "Modal",
        key: generateReactKey(),
      },
      {
        type: "SLIDEOUT_WIDGET",
        widgetCardName: "Slideout",
        key: generateReactKey(),
      },
      {
        type: "CONTAINER_WIDGET",
        widgetCardName: "Container",
        key: generateReactKey(),
      },
      {
        type: "TABS_WIDGET",
        widgetCardName: "Tabs",
        key: generateReactKey(),
      },
    ],
  },
  {
    widgetCategory: "MEDIA",
    label: "Media",
    widgetCards: [
      {
        type: "IMAGE_WIDGET",
        widgetCardName: "Image",
        key: generateReactKey(),
      },
      {
        type: "VIDEO_WIDGET",
        widgetCardName: "Video",
        key: generateReactKey(),
      },
      {
        type: "ICON_WIDGET",
        widgetCardName: "Icon",
        key: generateReactKey(),
      },
    ],
  },
  {
    widgetCategory: "ADVANCED",
    label: "Advanced",
    widgetCards: [
      {
        type: "CODE_WIDGET",
        widgetCardName: "Code Editor",
        key: generateReactKey(),
      },
      {
        type: "DIFF_WIDGET",
        widgetCardName: "Diff Viewer",
        key: generateReactKey(),
      },
      {
        type: "IFRAME_WIDGET",
        widgetCardName: "iFrame Widget",
        key: generateReactKey(),
      },
      {
        type: "MAP_WIDGET",
        widgetCardName: "Map",
        key: generateReactKey(),
      },
      {
        type: "PDF_VIEWER_WIDGET",
        widgetCardName: "PDF Viewer",
        key: generateReactKey(),
      },
      {
        type: "GRID_WIDGET",
        widgetCardName: "Grid",
        key: generateReactKey(),
      },
      {
        type: "CHAT_WIDGET",
        widgetCardName: "Chat",
        key: generateReactKey(),
      },
      {
        type: "CALLOUT_WIDGET",
        widgetCardName: "Callout",
        key: generateReactKey(),
      },
    ],
  },
  {
    widgetCategory: "CUSTOM",
    label: "Custom components",
    widgetCards: [],
  },
];
