import JSON5 from "json5";
import _, {
  every,
  isBoolean,
  isNumber,
  isObject,
  isPlainObject,
  isString,
  isUndefined,
  toNumber,
  toString,
} from "lodash";
import moment, { isMoment } from "moment";
import {
  Condition0Type,
  Condition1Type,
  Condition2Type,
} from "legacy/widgets/TableWidget/TableFiltersConstants";
import { iconData } from "../../../constants/materialIcons";
import {
  ISO_DATE_FORMAT,
  VALIDATION_TYPES,
  ValidationResponse,
  ValidationType,
  Validator,
} from "../../constants/WidgetValidation";
import { WIDGET_TYPE_VALIDATION_ERROR } from "../../constants/messages";
import type {
  DataTree,
  DataTreeEntity,
} from "../../entities/DataTree/dataTreeFactory";
import type { WidgetProps } from "legacy/widgets";
import type { TableWidgetProps } from "legacy/widgets/TableWidget/TableWidgetConstants";

export const VALIDATORS: Record<ValidationType, Validator> = {
  [VALIDATION_TYPES.TEXT]: (value: any): ValidationResponse => {
    let parsed = value;
    if (isUndefined(value) || value === null) {
      return {
        isValid: true,
        parsed: value,
        message: "",
      };
    }
    if (isObject(value)) {
      return {
        isValid: true,
        parsed: JSON.stringify(value, null, 2),
        message: "",
      };
    }
    let isValid = isString(value);
    if (!isValid) {
      try {
        parsed = toString(value);
        isValid = true;
      } catch (e) {
        console.error(`Error when parsing ${value} to string`);
        console.error(e);
        return {
          isValid: false,
          parsed: "",
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.TEXT_EMPTY_NULL]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    if (value === "") {
      return {
        isValid: true,
        parsed: null,
      };
    }
    return VALIDATORS[VALIDATION_TYPES.TEXT](value, props, dataTree);
  },
  [VALIDATION_TYPES.REGEX]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed, message } = VALIDATORS[VALIDATION_TYPES.TEXT](
      value,
      props,
      dataTree,
    );

    if (isValid) {
      try {
        new RegExp(parsed);
      } catch (e) {
        return {
          isValid: false,
          parsed: parsed,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }

    return { isValid, parsed, message };
  },
  [VALIDATION_TYPES.NUMBER]: (value: any): ValidationResponse => {
    let parsed = value;
    if (isUndefined(value)) {
      return {
        isValid: false,
        parsed: 0,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    let isValid = isNumber(value);
    if (!isValid) {
      try {
        parsed = toNumber(value);
        if (isNaN(parsed)) {
          return {
            isValid: false,
            parsed: 0,
            message: WIDGET_TYPE_VALIDATION_ERROR,
          };
        }
        isValid = true;
      } catch (e) {
        console.error(`Error when parsing ${value} to number`);
        console.error(e);
        return {
          isValid: false,
          parsed: 0,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.NUMBER_ALLOW_UNDEFINED]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    if (isUndefined(value)) {
      return {
        isValid: true,
        parsed: value,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return VALIDATORS[VALIDATION_TYPES.NUMBER](value, props, dataTree);
  },
  [VALIDATION_TYPES.BOOLEAN]: (value: any): ValidationResponse => {
    let parsed = value;
    if (isUndefined(value)) {
      return {
        isValid: true,
        parsed: false,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    const isABoolean = isBoolean(value);
    const isStringTrueFalse = value === "true" || value === "false";
    const isValid = isABoolean || isStringTrueFalse;
    if (isStringTrueFalse) parsed = value !== "false";
    if (!isValid) {
      return {
        isValid: isValid,
        parsed: Boolean(parsed),
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return { isValid, parsed: Boolean(parsed) };
  },
  [VALIDATION_TYPES.OBJECT_OR_UNDEFINED]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    if (isUndefined(value)) {
      return {
        isValid: true,
        parsed: value,
        message: "",
      };
    }
    return VALIDATORS[VALIDATION_TYPES.OBJECT](value, props, dataTree);
  },
  [VALIDATION_TYPES.OBJECT]: (value: any): ValidationResponse => {
    let parsed = value;
    if (isUndefined(value)) {
      return {
        isValid: false,
        parsed: {},
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    let isValid = isObject(value);
    if (!isValid) {
      try {
        parsed = JSON.parse(value);
        isValid = true;
      } catch (e) {
        console.error(`Error when parsing ${value} to object`);
        console.error(e);
        return {
          isValid: false,
          parsed: {},
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.ARRAY]: (value: any): ValidationResponse => {
    let parsed = value;
    try {
      if (isUndefined(value)) {
        return {
          isValid: false,
          parsed: [],
          transformed: undefined,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
      if (isString(value)) {
        parsed = JSON5.parse(parsed as string);
      }
      if (!Array.isArray(parsed)) {
        return {
          isValid: false,
          parsed: [],
          transformed: parsed,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
      return { isValid: true, parsed, transformed: parsed };
    } catch (e) {
      console.error(e);
      return {
        isValid: false,
        parsed: [],
        transformed: parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
  },
  [VALIDATION_TYPES.NUMBER_ARRAY]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.ARRAY](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    } else if (!every(parsed, (datum) => typeof datum === "number")) {
      return {
        isValid: false,
        parsed: [],
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.TABS_DATA]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.ARRAY](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    } else if (!every(parsed, (datum) => isObject(datum))) {
      return {
        isValid: false,
        parsed: [],
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.TABLE_DATA]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, transformed, parsed } = VALIDATORS.ARRAY(
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed: [],
        transformed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    const isValidTableData = every(parsed, (datum) => {
      return (
        isPlainObject(datum) &&
        Object.keys(datum).filter((key) => isString(key) && key.length === 0)
          .length === 0
      );
    });
    if (!isValidTableData) {
      return {
        isValid: false,
        parsed: [],
        transformed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.CHART_DATA]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.ARRAY](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        transformed: parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    let validationMessage = "";
    let index = 0;
    const isValidChartData = every(
      parsed,
      (datum: { name: string; data: any }) => {
        const validatedResponse: {
          isValid: boolean;
          parsed: Array<unknown>;
          message?: string;
        } = VALIDATORS[VALIDATION_TYPES.ARRAY](datum.data, props, dataTree);
        validationMessage = `${index}##${WIDGET_TYPE_VALIDATION_ERROR}: [{ "x": "val", "y": "val" }]`;
        let isValidChart = validatedResponse.isValid;
        if (validatedResponse.isValid) {
          datum.data = validatedResponse.parsed;
          isValidChart = every(
            datum.data,
            (chartPoint: { x: string; y: any }) => {
              return (
                isObject(chartPoint) &&
                isString(chartPoint.x) &&
                !isUndefined(chartPoint.y)
              );
            },
          );
        }
        index++;
        return isValidChart;
      },
    );
    if (!isValidChartData) {
      return {
        isValid: false,
        parsed: [],
        transformed: parsed,
        message: validationMessage,
      };
    }
    return { isValid, parsed, transformed: parsed };
  },
  [VALIDATION_TYPES.PLOTLY_CHART_JSON]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.OBJECT](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    if (isValid && _.isObject(parsed) && _.isArray((parsed as any).data)) {
      return {
        isValid,
        parsed,
      };
    }

    return {
      isValid: false,
      parsed,
      message: WIDGET_TYPE_VALIDATION_ERROR,
    };
  },
  [VALIDATION_TYPES.FRACTION_DIGITS]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    // undefined will be treated to NOT trimming the digits
    if (_.isEmpty(value)) {
      return {
        isValid: true,
        parsed: undefined,
      };
    }
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.NUMBER](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }

    if (isValid && (parsed < 0 || parsed > 20)) {
      return {
        isValid: false,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }

    return {
      isValid,
      parsed,
    };
  },
  [VALIDATION_TYPES.ICONS]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    if (isUndefined(value)) {
      return {
        isValid: true,
        parsed: value,
      };
    }
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.TEXT](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed: undefined,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }

    if (!parsed?.trim().startsWith("<Icon") || !parsed?.trim().endsWith("/>")) {
      // If the icon is not in the format <Icon name="<iconName>" /> or <Icon url='<url>' />
      // then we simply check if the icon exists in the iconData object. This is for backward compatibility
      // since earlier the icon selector would directly resolve to the icon name.
      if (parsed?.trim() in iconData) {
        return {
          isValid: true,
          parsed,
        };
      } else {
        return {
          isValid: false,
          parsed: undefined,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }

    // There's two cases here:
    // 1. <Icon name="<iconName>" />
    // We confirm whether the icon exists in the iconData object
    // 2. <Icon url='<url>' />
    // We confirm whether the url is a valid url
    const nameMatch = parsed?.match(/name=['"](.*)['"]/);
    if (nameMatch) {
      const iconName = nameMatch[1];
      if (iconName in iconData) {
        return {
          isValid: true,
          parsed,
        };
      } else {
        return {
          isValid: false,
          parsed: undefined,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }
    const urlMatch = parsed?.match(/url=['"](.*)['"]/);
    if (urlMatch) {
      const url = urlMatch[1];
      try {
        new URL(url);
        return {
          isValid: true,
          parsed,
        };
      } catch (e) {
        return {
          isValid: false,
          parsed: undefined,
          message: WIDGET_TYPE_VALIDATION_ERROR,
        };
      }
    }

    return {
      isValid: false,
      parsed: undefined,
    };
  },
  [VALIDATION_TYPES.MARKERS]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.ARRAY](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    } else if (
      !every(
        parsed,
        (datum) => VALIDATORS[VALIDATION_TYPES.LAT_LONG](datum, props).isValid,
      )
    ) {
      return {
        isValid: false,
        parsed: [],
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return { isValid, parsed };
  },
  [VALIDATION_TYPES.TABLE_SORT]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    if (!value) {
      return {
        isValid: true,
        parsed: undefined,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.OBJECT](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed: undefined,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    if (!Object.prototype.hasOwnProperty.call(parsed, "column")) {
      return {
        isValid: false,
        parsed: undefined,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    if (!isString(parsed.column)) {
      return {
        isValid: false,
        parsed: undefined,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    if (
      !Object.prototype.hasOwnProperty.call(
        (props as WidgetProps as TableWidgetProps).primaryColumns,
        parsed.column,
      ) &&
      !Object.prototype.hasOwnProperty.call(
        (props as WidgetProps as TableWidgetProps).derivedColumns,
        parsed.column,
      )
    ) {
      return {
        isValid: false,
        parsed: undefined,
        message: `${WIDGET_TYPE_VALIDATION_ERROR}: Table Sort: column does not exist ${JSON.stringify(
          parsed.column,
        )}`,
      };
    }
    if (
      Object.prototype.hasOwnProperty.call(parsed, "asc") &&
      !_.isBoolean(parsed.asc)
    ) {
      return {
        isValid: false,
        parsed: undefined,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    for (const key of Object.keys(parsed)) {
      switch (key) {
        case "column":
        case "asc":
          break;
        default:
          return {
            isValid: false,
            parsed: undefined,
            message: `${WIDGET_TYPE_VALIDATION_ERROR}: Invalid field ${JSON.stringify(
              key,
            )}`,
          };
      }
    }
    return {
      isValid: true,
      parsed: {
        column: parsed.column,
        asc: parsed.asc ?? true,
      },
      message: WIDGET_TYPE_VALIDATION_ERROR,
    };
  },
  [VALIDATION_TYPES.FILTERS_DATA]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    // treat empty string or undefined as empty filters
    if (!value) {
      return {
        isValid: true,
        parsed: {},
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.OBJECT](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    for (const [key, valueOpt] of Object.entries(parsed)) {
      switch (key) {
        case "byColumn": {
          const { isValid } = VALIDATORS[VALIDATION_TYPES.OBJECT](
            valueOpt,
            props,
            dataTree,
          );
          if (!isValid) {
            return {
              isValid,
              parsed,
              message: WIDGET_TYPE_VALIDATION_ERROR,
            };
          }
          for (const valueCol of Object.values(valueOpt as any)) {
            for (const [key, valueColFil] of Object.entries(valueCol as any)) {
              switch (key) {
                case "condition": {
                  const { isValid } = VALIDATORS[VALIDATION_TYPES.OBJECT](
                    valueColFil,
                    props,
                    dataTree,
                  );
                  if (!isValid) {
                    return {
                      isValid,
                      parsed,
                      message: WIDGET_TYPE_VALIDATION_ERROR,
                    };
                  }
                  const type = (valueColFil as any).type;
                  if (!_.isString(type)) {
                    return {
                      isValid: false,
                      parsed,
                      message: WIDGET_TYPE_VALIDATION_ERROR,
                    };
                  }
                  let paramCount: number;
                  if (type in Condition0Type) paramCount = 0;
                  else if (type in Condition1Type) paramCount = 1;
                  else if (type in Condition2Type) paramCount = 2;
                  else {
                    return {
                      isValid: false,
                      parsed,
                      message: `${WIDGET_TYPE_VALIDATION_ERROR}: Invalid condition type ${JSON.stringify(
                        key,
                      )}`,
                    };
                  }
                  for (const key of Object.keys(valueColFil as any)) {
                    if (key === "type") continue;
                    if (paramCount === 1) {
                      if (key === "param") continue;
                    } else if (key.startsWith("param")) {
                      const num = parseInt(key.substring("param".length));
                      if (!isNaN(num) && 1 <= num && num <= paramCount)
                        continue;
                    }
                    return {
                      isValid: false,
                      parsed,
                      message: `${WIDGET_TYPE_VALIDATION_ERROR}: Invalid field ${JSON.stringify(
                        key,
                      )}`,
                    };
                  }
                  break;
                }
                case "byValue": {
                  const { isValid } = VALIDATORS[VALIDATION_TYPES.OBJECT](
                    valueColFil,
                    props,
                    dataTree,
                  );
                  if (!isValid) {
                    return {
                      isValid,
                      parsed,
                      message: WIDGET_TYPE_VALIDATION_ERROR,
                    };
                  }
                  for (const [key, valueColFilByVal] of Object.entries(
                    valueColFil as any,
                  )) {
                    switch (key) {
                      case "excluded": {
                        const { isValid } = VALIDATORS[VALIDATION_TYPES.ARRAY](
                          valueColFilByVal,
                          props,
                          dataTree,
                        );
                        if (!isValid) {
                          return {
                            isValid,
                            parsed,
                            message: WIDGET_TYPE_VALIDATION_ERROR,
                          };
                        }
                        break;
                      }
                      default:
                        return {
                          isValid: false,
                          parsed,
                          message: `${WIDGET_TYPE_VALIDATION_ERROR}: Invalid field ${JSON.stringify(
                            key,
                          )}`,
                        };
                    }
                  }
                  break;
                }
                default:
                  return {
                    isValid: false,
                    parsed,
                    message: `${WIDGET_TYPE_VALIDATION_ERROR}: Invalid field ${JSON.stringify(
                      key,
                    )}`,
                  };
              }
            }
          }
          break;
        }
        default:
          return {
            isValid: false,
            parsed,
            message: `${WIDGET_TYPE_VALIDATION_ERROR}: Invalid field ${JSON.stringify(
              key,
            )}`,
          };
      }
    }
    return {
      isValid: true,
      parsed,
      message: WIDGET_TYPE_VALIDATION_ERROR,
    };
  },
  [VALIDATION_TYPES.OPTIONS_DATA]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ): ValidationResponse => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.ARRAY](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed: [],
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }

    return {
      isValid: true,
      parsed: parsed.map((value: unknown) => {
        if (_.isPlainObject(value)) {
          return value;
        }
        // Converts non-object values into pairs
        return { label: String(value), value: String(value) };
      }),
      message: "",
    };
  },
  [VALIDATION_TYPES.DATE]: (
    dateString: string,
    props: DataTreeEntity & { dateFormat?: string },
  ): ValidationResponse => {
    const dateFormat = props.dateFormat ? props.dateFormat : ISO_DATE_FORMAT;

    if (dateString === undefined) {
      return {
        isValid: false,
        parsed: "",
        message:
          WIDGET_TYPE_VALIDATION_ERROR + props.dateFormat
            ? props.dateFormat
            : "",
      };
    }
    const isValid = moment(dateString, dateFormat).isValid();
    if (!isValid) {
      return {
        isValid: isValid,
        parsed: "",
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return {
      isValid,
      parsed: dateString,
      message: isValid ? "" : WIDGET_TYPE_VALIDATION_ERROR,
    };
  },
  [VALIDATION_TYPES.DEFAULT_DATE]: (
    dateString: unknown,
    props: DataTreeEntity & {
      dateFormat?: string;
      minDate?: string;
      maxDate?: string;
      isRequired?: boolean;
    },
  ): ValidationResponse => {
    const dateFormat = props.dateFormat ? props.dateFormat : ISO_DATE_FORMAT;
    if (!dateString) {
      if (props.isRequired) {
        return {
          isValid: false,
          parsed: "",
          message:
            WIDGET_TYPE_VALIDATION_ERROR + props.dateFormat
              ? props.dateFormat
              : "",
        };
      }
      return {
        isValid: true,
        parsed: "",
        message: "",
      };
    }
    const parsedCurrentDate = moment(dateString, dateFormat);
    let isValid = parsedCurrentDate.isValid();
    const parsedMinDate = moment(props.minDate, dateFormat);
    const parsedMaxDate = moment(props.maxDate, dateFormat);
    let message = WIDGET_TYPE_VALIDATION_ERROR;

    // checking for max/min date range
    if (isValid) {
      if (
        parsedMinDate.isValid() &&
        parsedCurrentDate.isBefore(parsedMinDate)
      ) {
        isValid = false;
        message = "Date is before min date";
      }

      if (
        isValid &&
        parsedMaxDate.isValid() &&
        parsedCurrentDate.isAfter(parsedMaxDate)
      ) {
        isValid = false;
        message = "Date is before min date";
      }
    }
    if (!isValid) {
      return {
        isValid: isValid,
        parsed: "",
        message,
      };
    }
    if (isMoment(dateString)) {
      return {
        isValid: isValid,
        parsed: moment(dateString).format(dateFormat),
        message: "",
      };
    }
    return {
      isValid: isValid,
      parsed: dateString,
      message: "",
    };
  },
  [VALIDATION_TYPES.MIN_DATE]: (
    dateString: string,
    props: DataTreeEntity & { dateFormat?: string; defaultDate?: string },
  ): ValidationResponse => {
    const dateFormat = props.dateFormat ? props.dateFormat : ISO_DATE_FORMAT;
    if (dateString === undefined || dateString.trim() === "") {
      return {
        isValid: true,
        parsed: "",
      };
    }
    const parsedMinDate = moment(dateString, dateFormat);
    let isValid = parsedMinDate.isValid();
    if (!props.defaultDate) {
      return {
        isValid: isValid,
        parsed: dateString,
        message: "",
      };
    }
    const parsedDefaultDate = moment(props.defaultDate, dateFormat);

    if (
      isValid &&
      parsedDefaultDate.isValid() &&
      parsedDefaultDate.isBefore(parsedMinDate)
    ) {
      isValid = false;
    }
    if (!isValid) {
      return {
        isValid: isValid,
        parsed: "",
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return {
      isValid: isValid,
      parsed: dateString,
      message: "",
    };
  },
  [VALIDATION_TYPES.MAX_DATE]: (
    dateString: string,
    props: DataTreeEntity & { dateFormat?: string; defaultDate?: string },
  ): ValidationResponse => {
    const dateFormat = props.dateFormat ? props.dateFormat : ISO_DATE_FORMAT;
    if (dateString === undefined || dateString.trim() === "") {
      return {
        isValid: true,
        parsed: "",
      };
    }
    const parsedMaxDate = moment(dateString, dateFormat);
    let isValid = parsedMaxDate.isValid();
    if (!props.defaultDate) {
      return {
        isValid: isValid,
        parsed: dateString,
        message: "",
      };
    }
    const parsedDefaultDate = moment(props.defaultDate, dateFormat);

    if (
      isValid &&
      parsedDefaultDate.isValid() &&
      parsedDefaultDate.isAfter(parsedMaxDate)
    ) {
      isValid = false;
    }
    if (!isValid) {
      return {
        isValid: isValid,
        parsed: "",
        message: WIDGET_TYPE_VALIDATION_ERROR,
      };
    }
    return {
      isValid: isValid,
      parsed: dateString,
      message: "",
    };
  },
  [VALIDATION_TYPES.SELECTED_TAB]: (
    value: any,
    props: DataTreeEntity & { tabs?: { label: string; id: string }[] | string },
  ): ValidationResponse => {
    const tabs =
      props.tabs && isString(props.tabs)
        ? JSON.parse(props.tabs)
        : props.tabs && Array.isArray(props.tabs)
        ? props.tabs
        : [];
    const tabNames = tabs.map((i: { label: string; id: string }) => i.label);
    const isValidTabName = tabNames.includes(value);
    return {
      isValid: isValidTabName,
      parsed: value,
      message: isValidTabName ? "" : "Tab name does not exist.",
    };
  },
  [VALIDATION_TYPES.DEFAULT_OPTION_VALUE]: (
    value: string | string[],
    props: DataTreeEntity & { selectionType?: string; isMultiple?: boolean },
    dataTree?: DataTree,
  ) => {
    let values = value;

    if (props) {
      if (!props.isMultiple) {
        return VALIDATORS[VALIDATION_TYPES.TEXT_EMPTY_NULL](
          value,
          props,
          dataTree,
        );
      } else {
        if (typeof value === "string") {
          try {
            values = JSON.parse(value);
            if (!Array.isArray(values)) {
              throw new Error();
            }
          } catch {
            values = value.length ? value.split(",") : [];
            if (values.length > 0) {
              values = values.map((value) => value.trim());
            }
          }
        }
      }
    }

    if (Array.isArray(values)) {
      values = _.uniq(values);
    }

    return {
      isValid: true,
      parsed: values,
    };
  },
  [VALIDATION_TYPES.DEFAULT_SELECTED_ROW]: (
    value: string | string[],
    props: DataTreeEntity & { multiRowSelection?: boolean },
  ) => {
    let values = value;

    if (props) {
      if (props.multiRowSelection) {
        if (typeof value === "string") {
          try {
            values = JSON.parse(value);
            if (!Array.isArray(values)) {
              throw new Error();
            }
          } catch {
            values = value.length ? value.split(",") : [];
            if (values.length > 0) {
              let numericValues = values.map((value) => {
                return isNumber(value.trim()) ? -1 : Number(value.trim());
              });
              numericValues = _.uniq(numericValues);
              return {
                isValid: true,
                parsed: numericValues,
              };
            }
          }
        }
      } else {
        try {
          if (value === "" || value == null) {
            return {
              isValid: true,
              parsed: -1,
            };
          }
          let parsed = Math.trunc(toNumber(value));
          if (isNaN(parsed) || !isFinite(parsed)) parsed = -1;
          return {
            isValid: true,
            parsed: parsed,
          };
        } catch (e) {
          return {
            isValid: true,
            parsed: -1,
          };
        }
      }
    }
    return {
      isValid: true,
      parsed: values,
    };
  },
  [VALIDATION_TYPES.COLUMN_PROPERTIES_ARRAY]: (
    value: any,
    props: DataTreeEntity,
    dataTree?: DataTree,
  ) => {
    const { isValid, parsed } = VALIDATORS[VALIDATION_TYPES.ARRAY](
      value,
      props,
      dataTree,
    );
    if (!isValid) {
      return {
        isValid,
        parsed,
        transformed: parsed,
        message: "",
      };
    }
    const isValidProperty = (data: any) =>
      isString(data) || isNumber(data) || isBoolean(data);
    const isValidColumns = every(parsed, (datum: any) => {
      const validatedResponse: {
        isValid: boolean;
        parsed: Record<string, unknown>;
        message?: string;
      } = VALIDATORS[VALIDATION_TYPES.OBJECT](datum, props, dataTree);
      const isValidColumn = validatedResponse.isValid;
      if (isValidColumn) {
        for (const key in validatedResponse.parsed) {
          const columnProperty = validatedResponse.parsed[key];
          let isValidColumnProperty = true;
          if (Array.isArray(columnProperty)) {
            isValidColumnProperty = every(columnProperty, (data: any) => {
              return isValidProperty(data);
            });
          } else if (!isObject(columnProperty)) {
            isValidColumnProperty = isValidProperty(columnProperty);
          }
          if (!isValidColumnProperty) {
            validatedResponse.parsed[key] = "";
          }
        }
      }
      return isValidColumn;
    });
    if (!isValidColumns) {
      return {
        isValid: isValidColumns,
        parsed: [],
        transformed: parsed,
        message: "",
      };
    }
    return { isValid, parsed, transformed: parsed };
  },
  [VALIDATION_TYPES.LAT_LONG]: (unparsedValue: {
    lat?: number;
    long?: number;
    [x: string]: any;
  }): ValidationResponse => {
    let value = unparsedValue;
    const invalidResponse = {
      isValid: false,
      parsed: undefined,
      message: WIDGET_TYPE_VALIDATION_ERROR,
    };

    if (isString(unparsedValue)) {
      try {
        value = JSON.parse(unparsedValue);
      } catch (e) {
        console.error(`Error when parsing string as object`);
      }
    }

    const { lat, long } = value || {};
    const validLat = typeof lat === "number" && lat <= 90 && lat >= -90;
    const validLong = typeof long === "number" && long <= 180 && long >= -180;

    if (!validLat || !validLong) {
      return invalidResponse;
    }

    return {
      isValid: true,
      parsed: value,
    };
  },
  [VALIDATION_TYPES.URL_OR_BASE64]: (value: any): ValidationResponse => {
    if (!value) {
      return {
        isValid: true,
        parsed: "",
        message: "",
      };
    }

    const base64Regex =
      /^data:application\/pdf;base64,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

    if (base64Regex.test(value as string)) {
      // base 64 is valid
      return {
        isValid: true,
        parsed: value,
      };
    }

    try {
      const newUrl = new URL(value as string);
      if (newUrl.protocol !== "https:")
        return {
          isValid: false,
          parsed: "",
          message: "Only HTTPS protocol supported",
        };

      // URL is valid
      return {
        isValid: true,
        parsed: newUrl.href,
      };
    } catch (error) {
      return {
        isValid: false,
        parsed: "",
        message: "Provided URL / Base64 is invalid.",
      };
    }
  },
  [VALIDATION_TYPES.ANY]: (value: any): ValidationResponse => {
    return {
      isValid: true,
      parsed: value,
    };
  },
};
