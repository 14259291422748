import {
  DatasourceDto,
  Plugin,
  VariableMode,
  VariableType,
} from "@superblocksteam/shared";
import { type NavItem } from "hooks/ui/useNavigateTo";
import { Flag } from "store/slices/featureFlags";
import { ActionDto } from "../../apis/types";

/**
 * Basic control flow types
 */

export enum BlockType {
  CONDITION = "CONDITION",
  LOOP = "LOOP",
  PARALLEL = "PARALLEL",
  TRY_CATCH = "TRY_CATCH",
  VARIABLES = "VARIABLES",
  RETURN = "RETURN",
  BREAK = "BREAK",
  WAIT = "WAIT",
  THROW = "THROW",
  STEP = "STEP",
  SEND = "SEND",
  STREAM = "STREAM",
}

/**
 * Block config types
 */
export type StepConfig = ActionDto;

/**
 * Block types
 */
export type GenericBlock = {
  // Name acts as the id here
  name: string;
  parentId?: GenericBlock["name"];
  type: BlockType;
  config:
    | ConditionControl
    | LoopControl
    | ParallelControl
    | TryCatchControl
    | VariablesControl
    | ReturnControl
    | BreakControl
    | WaitControl
    | ThrowControl
    | StepConfig
    | SendControl
    | StreamControl;
};

export type StepBlock = GenericBlock & {
  type: BlockType.STEP;
  config: StepConfig;
};

export type ControlBlock = Omit<GenericBlock, "type"> & {
  type: Exclude<BlockType, BlockType.STEP>;
};

/**
 * Conditional control
 */
export type ConditionSection = {
  condition: string;
  blocks: GenericBlock["name"][];
};
export type ConditionControl = {
  if: ConditionSection;
  elseIf?: ConditionSection[];
  else?: GenericBlock["name"][];
};

export type ConditionControlBlock = GenericBlock & {
  type: BlockType.CONDITION;
  config: ConditionControl;
};

/**
 * Loop control
 */
export enum LoopType {
  UNSPECIFIED = "UNSPECIFIED",
  FOR = "FOR",
  FOREACH = "FOREACH",
  WHILE = "WHILE",
}

export type LoopControl = {
  // range resolves to an array or a number based on a string literal or binding
  // Ex: {{ Step1.output }} which could be [1,2,3] or 3
  // or a literal array [1,2,3]
  range: string;
  type: LoopType;
  blocks: GenericBlock["name"][];
  variables: {
    item: string;
    index: string;
  };
};

export type LoopControlBlock = GenericBlock & {
  type: BlockType.LOOP;
  config: LoopControl;
};

/**
 * Parallel control
 */
export enum ParallelWait {
  WAIT_UNSPECIFIED = "WAIT_UNSPECIFIED",
  // Wait for every thread to complete.
  WAIT_ALL = "WAIT_ALL",
  // Do not wait for any thread to complete.
  WAIT_NONE = "WAIT_NONE",
}
export type ParallelControl = {
  static?: {
    paths?: {
      [name: string]: GenericBlock["name"][];
    };
  }; // default 2 paths: "path1", "path2"
  dynamic?: {
    variables: {
      item: string;
    };
    paths: string;
    blocks?: GenericBlock["name"][];
  };
  poolSize?: number;
  wait: ParallelWait; // default WAIT_NONE
};

export type ParallelControlBlock = GenericBlock & {
  type: BlockType.PARALLEL;
  config: ParallelControl;
};

export type DynamicParallelControlBlock = GenericBlock & {
  type: BlockType.PARALLEL;
  config: ParallelControl & {
    dynamic: NonNullable<ParallelControl["dynamic"]>;
  };
};

/**
 * Try/catch control
 */
export type TryCatchControl = {
  try: GenericBlock["name"][];
  catch: GenericBlock["name"][];
  finally?: GenericBlock["name"][];
  variables: {
    error: string;
  };
};

export type TryCatchControlBlock = GenericBlock & {
  type: BlockType.TRY_CATCH;
  config: TryCatchControl;
};

/**
 * Variables control
 */

export type VariableEntry = {
  key: string;
  value: string;
  mode: VariableMode;
  type: VariableType;
  editable?: boolean;
};

export type VariablesControl = {
  variables: VariableEntry[];
};

export type VariablesControlBlock = GenericBlock & {
  type: BlockType.VARIABLES;
  config: VariablesControl;
};

/**
 * Break control
 */

export type BreakControl = {
  condition: string;
};

export type BreakControlBlock = GenericBlock & {
  type: BlockType.BREAK;
  config: BreakControl;
};

/**
 * Return control
 */

export type ReturnControl = {
  data: string;
};

export type ReturnControlBlock = GenericBlock & {
  type: BlockType.RETURN;
  config: ReturnControl;
};

/**
 * Wait control
 */

export type WaitControl = {
  condition: string;
};

export type WaitControlBlock = GenericBlock & {
  type: BlockType.WAIT;
  config: WaitControl;
};

/**
 *  Throw control
 */
export type ThrowControl = {
  error: string;
};

export type ThrowControlBlock = GenericBlock & {
  type: BlockType.THROW;
  config: ThrowControl;
};

/**
 * Send control
 */

export type SendControl = {
  message: string;
};

export type SendControlBlock = GenericBlock & {
  type: BlockType.SEND;
  config: SendControl;
};

/**
 * Stream control
 */

export type StreamControl = {
  trigger?: GenericBlock["name"][]; // This will only ever by a single block
  process?: GenericBlock["name"][];
  autoSend: boolean;
  variables: {
    item: string;
  };
};

export type StreamControlBlock = GenericBlock & {
  type: BlockType.STREAM;
  config: StreamControl;
};

/*
 * Types for UI components
 */

export interface Warning {
  message: string | React.ReactNode;
  level?: "warn" | "info" | "none";
  buttonLabel?: string;
  buttonAction?: {
    type: "OPEN_ENTITY";
    payload: NavItem;
  };
  // can override the default tooltip width
  tooltipWidth?: number;
}

export interface HelpContent {
  description?: string;
  imageSrc?: string;
  warning?: Warning;
  link?: string;
  documentation?: string;
}

interface ConditionHelpContent extends HelpContent {
  fieldHelpContent: {
    condition: HelpContent;
    showElse: HelpContent;
  };
}

interface LoopHelpContent extends HelpContent {
  fieldHelpContent: {
    forIterations: HelpContent;
    forEachIterations: HelpContent;
    whileCondition: HelpContent;
  };
}

interface ParallelHelpContent extends HelpContent {
  fieldHelpContent: {
    parallelType: HelpContent;
    waitFor: HelpContent;
    enablePooling: HelpContent;
    poolSize: HelpContent;
    dynamicItems: HelpContent;
  };
}

interface TryCatchHelpContent extends HelpContent {
  fieldHelpContent: {
    showFinally: HelpContent;
  };
}

interface StreamHelpContent extends HelpContent {
  fieldHelpContent: {
    autoSend: HelpContent;
    showProcess: HelpContent;
  };
}

interface BreakHelpContent extends HelpContent {
  fieldHelpContent: {
    ifCondition: HelpContent;
  };
}

export type HelpContentMap = {
  [BlockType.STEP]: HelpContent;
  [BlockType.CONDITION]: ConditionHelpContent;
  [BlockType.LOOP]: LoopHelpContent;
  [BlockType.PARALLEL]: ParallelHelpContent;
  [BlockType.TRY_CATCH]: TryCatchHelpContent;
  [BlockType.STREAM]: StreamHelpContent;
  [BlockType.BREAK]: BreakHelpContent;
  [BlockType.RETURN]: HelpContent;
  [BlockType.WAIT]: HelpContent;
  [BlockType.THROW]: HelpContent;
  [BlockType.SEND]: HelpContent;
  [BlockType.VARIABLES]: HelpContent;
};

export type AddAPIBlockOption = {
  id: DatasourceDto["id"] | GenericBlock["name"];
  type: BlockType;
  optionName: DatasourceDto["name"] | string; // could be a block name
  plugin?: Plugin;
  pluginId?: Plugin["id"];
  datasource?: DatasourceDto;
  icon?: Plugin["iconLocation"];
  iconComponent?: React.ReactChild;
  disabled?: boolean;
  disabledReason?: string;
  sortPriority?: number; // higher value sorts towards the top
  featureFlag?: Flag; // flag to check before displaying this block
  hasBetaTag?: boolean;
};

/*
 * The augmented DSL we use in the FE
 */
export type ControlFlowFrontendDSL = {
  rootBlocks: GenericBlock["name"][];
  blocks: Record<GenericBlock["name"], GenericBlock>;
};
